import React from "react";

import {
  base,
  topSection,
  graphicContainer,
  graphic,
  checklist,
  quoteContainer,
  logosContainer,
  buttonContainer,
} from "./HomeSection.module.scss";

import AnimatedSvg from "../components/AnimatedSvg";
import Quote from "../components/Quote";
import Button from "../components/Button";
import Checklist from "../partials/Checklist";
import LogoCarousel from "../partials/LogoCarousel";

import { combine, getClassNameModifiers } from "../utils/classNames";

const HomeSection = props => {
  const {
    className,
    heading1,
    heading2,
    graphics,
    checklistItems,
    quote,
    quoteAuthor,
    logos,
    logosHeight,
    theme,
    href,
  } = props;

  const modifiers = getClassNameModifiers(props, ["theme", "isFlipped"]);

  const cloudSrc =
    theme === "cerise" ? "/images/blob-cerise-home.svg" : "/images/blob-navy-home.svg";

  return (
    <section className={combine(base, modifiers, className)}>
      <div className={combine(topSection, modifiers)}>
        <h1>{heading1}</h1>
        <img src={cloudSrc} alt="" />
        <div className={combine(graphicContainer, modifiers)}>
          <AnimatedSvg className={combine(graphic, modifiers)} graphics={graphics} />
          <Checklist className={combine(checklist, modifiers)} items={checklistItems} />
        </div>
        <Quote
          className={quoteContainer}
          quote={quote}
          author={quoteAuthor}
          color={theme}
        />
      </div>
      <h2>{heading2}</h2>
      <div className={logosContainer}>
        <LogoCarousel logos={logos} height={logosHeight} />
      </div>
      <div className={buttonContainer}>
        <Button color={theme} href={href}>
          Learn more
        </Button>
      </div>
    </section>
  );
};

export default HomeSection;
