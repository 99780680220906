import React, { useState, useEffect } from "react";

import { base } from "./Typewriter.module.scss";

import { combine } from "../utils/classNames";

const Typewriter = props => {
  const { className, words } = props;

  const [chars, setChars] = useState("");

  useEffect(() => {
    let output = []
    const interval = 100;

    words.forEach((word, wordKey) => {
      // Pause before starting
      [...Array(5).keys()].forEach(() => output.push(""));

      // Go forwards
      word.split("").forEach((_, charKey) => {
        output.push(word.slice(0, charKey));
      });

      // Add space between
      [...Array(10).keys()].forEach(() => output.push(word));

      // Go backwards
      word.split("").forEach((_, charKey) => {
        output.push(word.slice(0, word.length - charKey));
      });

      // Finish on an empty string
      output.push("");
    });

    const iterate = () => {
      output.forEach((chars, index) => {
        setTimeout(() => setChars(chars), index * interval);
      });
    };

    iterate();
    setInterval(iterate, output.length * interval);
  }, []);

  return <span className={combine(base, className)}>{chars}</span>;
};

export default Typewriter;
