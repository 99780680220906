import React from "react";

import { base, buttonContainer, graphic, container, typewriter } from "./HomeBanner.module.scss";

import Typewriter from "../components/Typewriter";
import CTAButtons from "./CTAButtons";
import { useMediaQuery } from "../utils/hooks";

const ctaButtons = [{ label: "Start today", href: "/book-a-demo" }];

const HomeBanner = props => {
  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <section className={base}>
      <h1>
        Powering shopper marketing, retail media and b2b partnerships
      </h1>
      <CTAButtons className={buttonContainer} buttons={ctaButtons} />
      <HomeGraphic />
    </section>
  );
};

const HomeGraphic = () => {
  return (
    <div className={graphic}>
      <div className={container}>
        <img
          src="/images/home-graphic/ring-1.svg"
          alt=""
          style={{
            right: "24.714rem",
            bottom: "-0.875rem",
            width: "10.708rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/ring-2.svg"
          alt=""
          style={{
            right: "4.745rem",
            bottom: "8.125rem",
            width: "6.946rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/ring-3.svg"
          alt=""
          style={{
            right: "24.251rem",
            bottom: "23.5rem",
            width: "6.946rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/image-1.png"
          alt=""
          style={{
            right: "18.636rem",
            bottom: "6.313rem",
            width: "12.966rem",
            zIndex: 3,
          }}
        />
        <img
          src="/images/home-graphic/image-2.png"
          alt=""
          style={{
            right: "3.761rem",
            bottom: "12.938rem",
            width: "12.792rem",
            zIndex: 3,
          }}
        />
        <img
          src="/images/home-graphic/image-3.png"
          alt=""
          style={{
            right: "6.308rem",
            bottom: "-1.688rem",
            width: "12.792rem",
            zIndex: 3,
          }}
        />
        <img
          src="/images/home-graphic/image-4.png"
          alt=""
          style={{
            right: "20.662rem",
            bottom: "-2.875rem",
            width: "6.946rem",
            zIndex: 3,
          }}
        />
        <img
          src="/images/home-graphic/image-5.png"
          alt=""
          style={{
            right: "27.145rem",
            bottom: "20rem",
            width: "6.946rem",
            zIndex: 3,
          }}
        />
        <img
          src="/images/home-graphic/bubble-1.svg"
          alt=""
          style={{
            right: "4.166rem",
            bottom: "25.688rem",
            width: "1.852rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-2.svg"
          alt=""
          style={{
            right: "2.661rem",
            bottom: "24.438rem",
            width: "0.926rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-3.svg"
          alt=""
          style={{
            right: "14.179rem",
            bottom: "25.313rem",
            width: "0.926rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-4.svg"
          alt=""
          style={{
            right: "4.745rem",
            bottom: "7.813rem",
            width: "1.852rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-5.svg"
          alt=""
          style={{
            right: "3.992rem",
            bottom: "-1.606rem",
            width: "0.926rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-6.svg"
          alt=""
          style={{
            right: "35.538rem",
            bottom: "24.5rem",
            width: "0.926rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-7.svg"
          alt=""
          style={{
            right: "24.54rem",
            bottom: "20.313rem",
            width: "0.926rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-8.svg"
          alt=""
          style={{
            right: "29.402rem",
            bottom: "2.536rem",
            width: "1.852rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-9.svg"
          alt=""
          style={{
            right: "31.255rem",
            bottom: "1.5rem",
            width: "0.926rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-10.svg"
          alt=""
          style={{
            right: "24.135rem",
            bottom: "-5.751rem",
            width: "1.852rem",
            zIndex: 1,
          }}
        />
        <img
          src="/images/home-graphic/bubble-11.svg"
          alt=""
          style={{
            right: "26.636rem",
            bottom: "-6.876rem",
            width: "0.926rem",
            zIndex: 1,
          }}
        />
      </div>
    </div>
  );
};

export default HomeBanner;
