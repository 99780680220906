import React from "react";

import {
  categoriesSection,
  copySection,
  partnersSection,
  brandsSection,
  bookADemo,
  link,
} from "./index.module.scss";

import Layout from "../layouts/Layout";
import Link from "../components/Link";
import HomeBanner from "../partials/HomeBanner";
import HomeCategoriesSection from "../partials/HomeCategoriesSection";
import VerticalCategoriesSection from "../partials/VerticalCategoriesSection";
import HomeSection from "../partials/HomeSection";
import BookADemoBanner from "../partials/BookADemoBanner";
import { useMediaQuery } from "../utils/hooks";
import { combine } from "../utils/classNames";

const IndexPage = props => {
  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <Layout>
      <HomeBanner />
      <HomeCategoriesSection className="mobile-only" />
      <VerticalCategoriesSection
        className={combine(categoriesSection, "desktop-only")}
        noCTA
        heading="Making it easier for all businesses to buy and sell omnichannel media"
        theme="navy"
        categories={[
          {
            label: "On Premise",
            iconSrc: "/images/onsite.svg",
            activationTypes: [
              "Pop ups",
              "Product sampling",
              "POP displays",
              "In-store experiences",
              "Digital displays",
            ],
            color: "light-blue",
          },
          {
            label: "In home",
            iconSrc: "/images/home.svg",
            activationTypes: [
              "In-box sampling",
              "Curb-side sampling",
              "In-box inserts",
              "Mailer inserts",
              "Take-home magazines",
            ],
            color: "topaz",
          },
          {
            label: "Online",
            iconSrc: "/images/online.svg",
            activationTypes: [
              "Digital sponsorship",
              "Social sponsorship",
              "Email sponsorship",
              "Digital catalogues",
              "Digital offers",
            ],
            color: "blue",
          },
        ]}
      />
      <section className={copySection}>
        <h2>
          Our technology helps brands discover and book targeted, high-engagement activations, while
          powering business to monetize and maximize their media potential
        </h2>
      </section>
      <HomeSection
        className={partnersSection}
        heading1="Brandcrush for Media Owners"
        heading2="Join the 1,000+ Media Owners already using Brandcrush"
        graphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/partners-graphic@3x.png",
            x: isMobile ? 0.48 : 1.063,
            y: isMobile ? 2.61 : 5.75,
            width: isMobile ? 17.7 : 38.938,
            delay: 0.2,
          },
        ]}
        checklistItems={[
          "Transform your in-store media, out-of-store media and brand partnerships from antiquated PDF packs and spreadsheet management to a scalable solution that the worlds’ leading brands and agencies trust",
          "Make your media opportunities discoverable and easily bookable with self serve media portals",
          "Unlock and grow your media revenue without having to grow your team with smart inventory management & activation workflow tools",
        ]}
        quote="We can authentically introduce brands to millions of highly targeted and attractive households each week through our parcels, our insert perks as well as our social and digital channels. We needed a platform to streamline and scale our media sales. With Brandcrush we now have one simple platform to manage it all. It helps us manage our unique media inventory and workflow requirements and gives us the tools to maximize our revenue potential."
        quoteAuthor="Louise Branth, Commercial Partnerships, Hellofresh United Kingdom"
        logos={partnerLogos(isMobile)}
        logosHeight="5.0"
        theme="navy"
        href="/partners"
      />
      <HomeSection
        className={brandsSection}
        heading1="Brandcrush for Brands & Agencies"
        heading2="Trusted by the world's leading brands and agencies"
        graphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/brands-graphic-1@3x.png",
            x: isMobile ? 0.57 : 1.25,
            y: isMobile ? 7.01 : 15.438,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.05,
          },
          {
            image: "/images/brands-graphic-2@3x.png",
            x: isMobile ? 4.96 : 10.938,
            y: isMobile ? 7.01 : 15.438,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.1,
          },
          {
            image: "/images/brands-graphic-3@3x.png",
            x: isMobile ? 9.3 : 20.626,
            y: isMobile ? 7.01 : 15.438,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.15,
          },
          {
            image: "/images/brands-graphic-4@3x.png",
            x: isMobile ? 13.7 : 30.314,
            y: isMobile ? 7.01 : 15.438,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.2,
          },
          {
            image: "/images/brands-graphic-5@3x.png",
            x: isMobile ? 0.57 : 1.25,
            y: isMobile ? 2.18 : 4.813,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.25,
          },
          {
            image: "/images/brands-graphic-6@3x.png",
            x: isMobile ? 4.96 : 10.938,
            y: isMobile ? 2.18 : 4.813,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.3,
          },
          {
            image: "/images/brands-graphic-7@3x.png",
            x: isMobile ? 9.3 : 20.626,
            y: isMobile ? 2.18 : 4.813,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.35,
          },
          {
            image: "/images/brands-graphic-8@3x.png",
            x: isMobile ? 13.7 : 30.314,
            y: isMobile ? 2.18 : 4.813,
            width: isMobile ? 4.4 : 9.688,
            delay: 0.4,
          },
        ]}
        checklistItems={[
          <>
            <Link className={link} href="/brand/signup">
              Free signup
            </Link>{" "}
            and no platform fees
          </>,
          "Discover thousands of untapped opportunities to reach consumers where they live, shop, work and play",
          "Leverage a powerful network of activation partners who offer authentic connections with hyper-targeted audiences",
        ]}
        quote="We used Brandcrush to collaborate with restaurants and butchers. The booking process was seamless and we received real-time photos and feedback via the platform. The BBQ sauce category is very saturated with consumers preferring products from niche or premium brands. To ensure meaningful trial, Bulls Eye wanted consumers to experience our authentic American BBQ flavour in a real BBQ environment - context was everything for this new product launch campaign."
        quoteAuthor="Hugo Gates, Marketing - Brand Manager, The Kraft Heinz Company"
        logos={brandLogos(isMobile)}
        logosHeight="3.388"
        theme="cerise"
        isFlipped
        href="/brands"
      />
      <section className={bookADemo}>
        <BookADemoBanner
          copy="Schedule a demo with our team to discover how Brandcrush can work for you."
          color="light-pink"
        />
      </section>
    </Layout>
  );
};

export const partnerLogos = isMobile => [
  {
    src: "/images/logos/partners/Supabarn_grey.png",
    alt: "",
    width: isMobile ? "4.5" : "5.688",
  },
  {
    src: "/images/logos/partners/blooms_grey.png",
    alt: "",
    width: isMobile ? "5" : "6",
  },
  {
    src: "/images/logos/partners/HillSt_grey.png",
    alt: "",
    width: isMobile ? "4.5" : "5.3",
  },
  {
    src: "/images/logos/partners/GreenChef_Grey.png",
    alt: "",
    width: isMobile ? "3.55" : "5.688",
  },
  {
    src: "/images/logos/partners/WeWork_Grey.png",
    alt: "",
    width: isMobile ? "4.18" : "6.688",
  },
  {
    src: "/images/logos/partners/Surfstitch_Grey.png",
    alt: "",
    width: isMobile ? "5.39" : "8.625",
  },
  {
    src: "/images/logos/partners/HelloFresh_Grey.png",
    alt: "",
    width: isMobile ? "3.25" : "5.25",
    mobileOrder: 2,
  },
  { src: "/images/logos/partners/F45_Grey.png", alt: "", width: isMobile ? "2.5" : "4.0" },
  {
    src: "/images/logos/partners/Catch_Grey.png",
    alt: "",
    width: isMobile ? "4.18" : "6.688",
  },
  {
    src: "/images/logos/partners/QIC_Grey.png",
    alt: "",
    width: isMobile ? "5.47" : "8.75",
  },
  {
    src: "/images/logos/partners/MarleySpoon_Grey.png",
    alt: "",
    width: isMobile ? "4.375" : "7.063",
    mobileOrder: 5,
  },
  {
    src: "/images/logos/partners/Monash_Grey.png",
    alt: "",
    width: isMobile ? "4.75" : "7.688",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/partners/LVLY_Grey.png",
    alt: "",
    width: isMobile ? "4.375" : "7.063",
    mobileOrder: 5,
  },
  {
    src: "/images/logos/partners/Dinnerly_Grey.png",
    alt: "",
    width: isMobile ? "4.33" : "6.938",
  },
  {
    src: "/images/logos/partners/Equiem_Grey.png",
    alt: "",
    width: isMobile ? "4.33" : "6.938",
  },
  {
    src: "/images/logos/partners/Ribs_Burgers_Grey.png",
    alt: "",
    width: isMobile ? "5.47" : "8.75",
  },
  {
    src: "/images/logos/partners/SLT_Grey.png",
    alt: "",
    width: isMobile ? "2.38" : "3.813",
  },
  {
    src: "/images/logos/partners/Geezy_Grey.png",
    alt: "",
    width: isMobile ? "4.875" : "7.938",
    mobileOrder: 4,
  },
  {
    src: "/images/logos/partners/Deliveroo_Grey.png",
    alt: "",
    width: isMobile ? "5.47" : "8.75",
  },
  {
    src: "/images/logos/partners/HomeChef_Grey.png",
    alt: "",
    width: isMobile ? "4.18" : "6.688",
  },
  {
    src: "/images/logos/partners/Naked_Grey.png",
    alt: "",
    width: isMobile ? "3.87" : "6.188",
  },
  {
    src: "/images/logos/partners/BluestoneLane_Grey.png",
    alt: "",
    width: isMobile ? "6.375" : "10.563",
    mobileOrder: 3,
  },
];

export const brandLogos = isMobile => [
  {
    src: "/images/logos/brands/Mondelez_Grey.png",
    alt: "",
    width: isMobile ? "3.55" : "7.813",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/GeneralMills_Grey.png",
    alt: "",
    width: isMobile ? "2.56" : "5.625",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/KraftHeinz_Grey.png",
    alt: "",
    width: isMobile ? "3.86" : "8.5",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Pepsi_Grey.png",
    alt: "",
    width: isMobile ? "4.55" : "10",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Coles_Grey.png",
    alt: "",
    width: isMobile ? "2.56" : "5.625",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Asahi_Grey.png",
    alt: "",
    width: isMobile ? "2.56" : "5.625",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Nestle_Grey.png",
    alt: "",
    width: isMobile ? "3.41" : "7.5",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Mars_Grey.png",
    alt: "",
    width: isMobile ? "2.56" : "5.625",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/HelloFresh_Grey.png",
    alt: "",
    width: isMobile ? "2.39" : "5.25",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/RedBull_Grey.png",
    alt: "",
    width: isMobile ? "2.56" : "5.625",
    mobileOrder: 1,
  },
  /*{
      src: "/images/logos/brands/Mindshare_Grey.png",
      alt: "",
      width: isMobile ? "3.86" : "8.5",
      mobileOrder: 1,
    },*/
  /*{
      src: "/images/logos/brands/OMD_Grey.png",
      alt: "",
      width: isMobile ? "2.39" : "5.25",
      mobileOrder: 1,
    },*/
  {
    src: "/images/logos/brands/Garnier_Grey.png",
    alt: "",
    width: isMobile ? "3.86" : "8.5",
    mobileOrder: 1,
  },
  /*{
      src: "/images/logos/brands/Starcom_Grey.png",
      alt: "",
      width: isMobile ? "3.86" : "8.5",
      mobileOrder: 1,
    },*/
  {
    src: "/images/logos/brands/Bega_Grey.png",
    alt: "",
    width: isMobile ? "2.39" : "5.25",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/brands/Naked_Grey.png",
    alt: "",
    width: isMobile ? "2.56" : "5.625",
    mobileOrder: 1,
  },
  /*{
      src: "/images/logos/brands/MediaCom_Grey.png",
      alt: "",
      width: isMobile ? "3.86" : "8.5",
      mobileOrder: 1,
    },*/
  /*{
      src: "/images/logos/brands/Publicis_Grey.png",
      alt: "",
      width: isMobile ? "2.56" : "5.625",
      mobileOrder: 1,
    },*/
  {
    src: "/images/logos/brands/Upfield_Grey.png",
    alt: "",
    width: isMobile ? "4.75" : "7.688",
    mobileOrder: 1,
  },
];

export const agencyLogos = isMobile => [
  {
    src: "/images/logos/agencies/Carat_Grey.png",
    alt: "",
    width: isMobile ? "4" : "6",
  },
  {
    src: "/images/logos/agencies/DentsuX_Grey.png",
    alt: "",
    width: isMobile ? "5" : "7.5",
  },
  {
    src: "/images/logos/agencies/Maverick-Grey.png",
    alt: "",
    width: isMobile ? "5.39" : "8.625",
  },
  {
    src: "/images/logos/agencies/Mediacom_Grey.png",
    alt: "",
    width: isMobile ? "5" : "8",
    mobileOrder: 2,
  },
  {
    src: "/images/logos/agencies/Mindshare_Grey.png",
    alt: "",
    width: isMobile ? "5.563" : "8.75",
  },
  {
    src: "/images/logos/agencies/OMD_Grey.png",
    alt: "",
    width: isMobile ? "3.625" : "4.625",
  },
  {
    src: "/images/logos/agencies/Phd_Grey.png",
    alt: "",
    width: isMobile ? "3" : "5",
  },
  {
    src: "/images/logos/agencies/Publicis_Grey.png",
    alt: "",
    width: isMobile ? "3" : "5",
    mobileOrder: 5,
  },
  {
    src: "/images/logos/agencies/RedHavas_Grey.png",
    alt: "",
    width: isMobile ? "3" : "5",
    mobileOrder: 1,
  },
  {
    src: "/images/logos/agencies/Starcom_Grey.png",
    alt: "",
    width: isMobile ? "5" : "8",
    mobileOrder: 5,
  },
  {
    src: "/images/logos/agencies/Wavemaker_Grey.png",
    alt: "",
    width: isMobile ? "7.5" : "10",
  },
  {
    src: "/images/logos/agencies/Zenith_Grey.png",
    alt: "",
    width: isMobile ? "3.875" : "5.625",
  },
];

export default IndexPage;
