import React from "react";

import { base } from "./BookADemoBanner.module.scss";

import Button from "../components/Button";

import { combine, getClassNameModifiers } from "../utils/classNames";

const BookADemoBanner = props => {
  const { className, color, copy } = props;

  const modifiers = getClassNameModifiers(props, ["color"]);

  const buttonColor = color === "light-pink" ? "cerise" : "navy";
  const imageSrc =
    color === "light-pink" ? "/images/book-a-demo-light-pink.svg" : "/images/book-a-demo.svg";

  return (
    <div className={combine(base, className, modifiers)}>
      <h2>Let's start a conversation</h2>
      <p>{copy}</p>
      <Button color={buttonColor} href="/book-a-demo">
        Schedule a demo
      </Button>
      <img src={imageSrc} alt="" />
    </div>
  );
};

export default BookADemoBanner;
