import React from "react";

import { base } from "./HomeCategoriesSection.module.scss";

import ActivationCategory from "../partials/ActivationCategory";
import { combine } from "../utils/classNames";

const HomeCategoriesSection = props => {
  const { className } = props;

  return (
    <section className={combine(base, className)}>
      <h2>Making it easier for all businesses to buy and sell omnichannel media</h2>
      <div>
        <ActivationCategory
          heading="On Premise"
          color="light-blue"
          activationTypes={[
            "Pop ups",
            "Product sampling",
            "Point of sale displays",
            "In-store experiences",
            "Digital displays",
          ]}
          imageSrc="/images/onsite-image@3x.png"
        />
        <ActivationCategory
          heading="In home"
          color="topaz"
          activationTypes={[
            "In-box sampling",
            "Curb-side sampling",
            "In-box inserts",
            "Mailer inserts",
            "Take-home magazines",
          ]}
          imageSrc="/images/at-home-image@3x.png"
        />
        <ActivationCategory
          heading="Online"
          color="blue"
          activationTypes={[
            "Digital sponsorship",
            "Social sponsorship",
            "Email sponsorship",
            "Digital catalogues",
            "Digital offers",
          ]}
          imageSrc="/images/online-image@3x.png"
        />
      </div>
    </section>
  );
};

export default HomeCategoriesSection;
